import React from 'react'
import { BusinessProfileType, PremiumTemplates, SocialMedia } from 'utils/constants'
import { PremiumUser, PremiumUserArgs } from 'models/premium-user'
import LayoutContainer from 'components/templates/premium/base/Layout'
import AvatarImg from 'assets/images/premium/p-02/avatar.png'
import { toContentfulRaw } from 'utils/format'

const defaultData: PremiumUserArgs = {
  isDemo: true,
  id: '1',
  name: 'Jorge',
  firstLastName: 'Robles',
  nameToDisplay: 'Katherine Garcia',
  avatarUrl: {
    file: {
      url: AvatarImg,
    },
  },
  businessProfileType: BusinessProfileType.Personal,
  template: PremiumTemplates.Base_02,
  metadata: {
    pageTitle: 'Professional P02',
  },
  jobs: [
    {
      id: '1',
      title: 'Coach Profesional',
    },
    {
      id: '2',
      title: 'Ponente y Formadora',
    },
  ],
  contact: {
    id: '1',
    phoneNumbers: ['+51901422305'],
    email: 'hola@playu.pe',
    addresses: [
      {
        id: '1',
        description: {
          description: 'Lima, Peru',
        },
      },
    ],
    socialMedia: [
      {
        order: 1,
        id: '1',
        type: SocialMedia.Facebook,
        link: 'https://www.facebook.com/playu.shop',
      },
      {
        order: 2,
        id: '2',
        type: SocialMedia.Instagram,
        link: 'https://instagram.com/playu.shop',
      },
      {
        order: 3,
        id: '3',
        type: SocialMedia.Youtube,
        link: 'https://www.youtube.com/channel/UCne87oRuqn9eEIZQ63A5EQg',
      },
      {
        order: 4,
        id: '4',
        type: SocialMedia.Linkedin,
        link: '#',
      },
    ],
  },
  services: [
    {
      id: '1',
      icon: 'fab fa-joomla',
      title: 'Coaching Profesional',
      description: {
        raw: toContentfulRaw(
          'Ayudo a mejorar tu marca personal, encontrar el trabajo de tus sueños y a triunfar en tus objetivos profesionales.'
        ),
      },
    },
    {
      id: '2',
      icon: 'fab fa-slack',
      title: 'Reinserción Laboral',
      description: {
        raw: toContentfulRaw(`Estaré contigo en todo tu proceso de Reinserción Laboral.
      Trabajamos sobre tu objetivo profesional, marca personal,
      comunicación, curriculum, linkedin, oportunidades de empleo y
      procesos de selección, entre otras muchas cosas.`),
      },
    },
    {
      id: '3',
      icon: 'fab fa-yelp',
      title: 'Ponencia sobre Personal Branding',
      description: {
        raw: toContentfulRaw(`Enseño a ambos (candidatos y empresas) una nueva estrategia
      que de verdad funcione. Acciones con las que conectar
      candidato y reclutador..`),
      },
    },
  ],
}

const PageComponent: React.FC = () => (
  <LayoutContainer user={new PremiumUser(defaultData)} template={PremiumTemplates.Base_02} />
)

export default PageComponent
